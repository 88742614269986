import './App.css';
import ProjectMain from './Components/ProjectMain/ProjectMain';
import "@fontsource/montserrat"; 

function App() {
  return (
    <div className="App">
     <ProjectMain />
    </div>
  );
}

export default App;
