import React from 'react';
import './HomePage.css';
import robotImage from '../../Assets/robotImage.png';

function HomePage() {
  return (
    <div className='HomePage'>
      <div className='HomePageLeftDiv'>
        <h3>Türkiye'nin <span>dijital</span> ajansı</h3>
        <h1>360 Derece Dijital Çözümlerimiz</h1>
        <p>
          Dijital dünyada markanızı büyütmek, hedef kitlenizle buluşmak ve iz bırakmak için buradayız.
          Sosyal medya yönetimi, web tasarımı, mobil uygulama ve reklam çözümleriyle 360 derece hizmet sunuyor, sizi dijitalde bir adım öne taşıyoruz!
        </p>
        <a href='tel:+908503463081'>
        <button>Hemen Ara</button>

        </a>
      </div>

      <div className='HomePageRightDiv'>
        <img src={robotImage} alt="Robot Illustration" />
      </div>
    </div>
  );
}

export default HomePage;
