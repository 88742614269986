import React from 'react'
import './Hizmetlerimiz.css'
import HizmetlerimizCard from './HizmetlerimizCard/HizmetlerimizCard'
import reklam from '../../Assets/reklam.png'
import web from '../../Assets/web.png'
import mobil from '../../Assets/mobil.png'
import sosyalmedya from '../../Assets/sosyalmedya.png'
import dijital from '../../Assets/dijital.png'
import seo from '../../Assets/seo.png' 
function Hizmetlerimiz() {
  return (
    <div id='Hizmetlerimiz' className='Hizmetlerimiz'>
        
        <div className='HizmetlerimizTitleDiv'>
        <h1>Hizmetlerimiz</h1>
        <p>Otrera Dijital, markalara sosyal medya, web tasarım, mobil uygulama, reklam, SEO ve dijital danışmanlık alanlarında 360 derece hizmet sunar.</p>
        </div>

       <div className='onur'>
       <div className='hizmetlerimizContainer'>
            <HizmetlerimizCard hizmetImg={reklam} hizmetTitle={"Reklam Yönetimi"} hizmetContent={"Meta (Facebook ve Instagram), Google Ads ve diğer dijital reklam platformlarında stratejik reklam kampanyaları yürüterek markanızın bilinirliğini ve satışlarını artırıyoruz. Doğru bütçe ve hedefleme ile reklam verimliliğini üst düzeye çıkarıyoruz."} />
            <HizmetlerimizCard hizmetImg={web} hizmetTitle={"Web Sitesi"} hizmetContent={"Modern, kullanıcı dostu ve mobil uyumlu web siteleri tasarlayarak markanızın dijital yüzünü oluşturuyoruz. Göz alıcı tasarımlar ve hızlı kullanıcı deneyimi sunarak müşterilerinizin sitenizde daha fazla vakit geçirmesini sağlıyoruz."} />
            <HizmetlerimizCard hizmetImg={mobil} hizmetTitle={"Mobil Uygulama"} hizmetContent={"İşinizi her an ulaşılabilir kılmak için mobil uygulamalar geliştiriyoruz. Hem iOS hem de Android platformlarında çalışan kullanıcı dostu, performanslı ve işlevsel uygulamalarla markanızın dijital erişimini genişletiyoruz."} />
            <HizmetlerimizCard hizmetImg={sosyalmedya} hizmetTitle={"Sosyal Medya Yönetimi"} hizmetContent={"Markanızın sosyal medya hesaplarını stratejik bir yaklaşımla yönetiyoruz. Hedef kitlenizi analiz ederek içerik üretimi, etkileşim artırma süreçlerini profesyonelce yürütüyoruz. Sosyal medya kanallarınızın etkin ve etkili bir şekilde büyümesini sağlıyoruz."} />
            <HizmetlerimizCard hizmetImg={dijital} hizmetTitle={"Dijital Danışmanlık"} hizmetContent={"Markanız için en uygun dijital stratejileri belirliyor, sektördeki yeniliklere göre sizi yönlendiriyor ve her zaman güncel kalmanızı sağlıyoruz."} />
            <HizmetlerimizCard hizmetImg={seo} hizmetTitle={"Seo"} hizmetContent={"Web sitenizi arama motorlarında üst sıralara taşımak için SEO hizmetleri sunuyoruz. Anahtar kelime analizi, içerik optimizasyonu ve teknik iyileştirmelerle organik trafiğinizi artırıyoruz."} />

        </div>
       </div>
    </div>
  )
}

export default Hizmetlerimiz