import React from 'react';
import './Referanslarımız.css';
import zincirPort from '../../Assets/zincirport.png';
import esenler from '../../Assets/esenler.png';
import drama from '../../Assets/drama.png';
import biletinle from '../../Assets/biletinle.png';
import turcable from '../../Assets/turcable.png';
import letImage from '../../Assets/let.png';

function Referanslarımız() {
  return (
    <div  className='Referanslarımız'>
      <div className='referansDiv'>
        <img src={zincirPort} alt="ZincirPort" />
        <img src={esenler} alt="Esenler" />
        <img src={drama} alt="Drama" />
        <img src={biletinle} alt="Biletinle" />
        <img src={letImage} alt="Let" />
        <img src={turcable} alt="Turcable" />
      </div>
    </div>
  );
}

export default Referanslarımız;
