import React from "react";
import "./Footer.css";
import { IoLogoInstagram } from "react-icons/io";
import { AiOutlineCopyright } from "react-icons/ai";

function Footer() {
  return (
   <div className="footerMain">
     <div className="Footer">
    
    <div className="footerContentDiv">
    <p className="footerH1">
        Bize ulaşın, sorularınızı müşteri temsilcimiz cevaplasın. 
      </p>

      <p className="footerH1"> 0850 346 30 81</p>
    </div>

      <div className="footerLineDiv"></div>

     <div className="footerContentDiv">
     <p className="footerH1">
       Teklif almak için bize bir eposta gönderin.
      </p>

      <p className="footerH1">
      info@otreradigital.com

      </p>
     </div>

      <div className="footerLineDiv"></div>

    <div  className="footerContentDiv">
    <p className="footerH1">
        Bizi Sosyal medya hesaplarımızdan takip edin 
      </p>

      <a href="https://www.instagram.com/otreradigital/" target="_blank">
      <IoLogoInstagram className="InstaIcon" />

      </a>

    </div>


    </div>

    <div className="telifDiv">
        <AiOutlineCopyright /> Tüm Hakları OTRERA DİJİTAL LTD.ŞTİ tarafından saklıdır.
    </div>
   </div>
  );
}

export default Footer;
