import React from 'react'
import './HizmetlerimizCard.css'
function HizmetlerimizCard({hizmetImg, hizmetTitle, hizmetContent}) {
  return (
    <div className='HizmetlerimizCard'>
        <img src={hizmetImg} />
        <h1>{hizmetTitle}</h1>
        <p>{hizmetContent}</p>

    </div>
  )
}

export default HizmetlerimizCard