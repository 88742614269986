import React from 'react'
import Navbar from '../Navbar/Navbar'
import HomePage from '../HomePage/HomePage'
import Referanslarımız from '../Referanslarımız/Referanslarımız'
import BizKimiz from '../BizKimiz/BizKimiz'
import Hizmetlerimiz from '../Hizmetlerimiz/Hizmetlerimiz'
import HappyCustomer from '../HappyCustomer/HappyCustomer'
import Comment from '../Comment/Comment'
import Footer from '../Footer/Footer'
function ProjectMain() {
  return (
    <div className='projectMain'>
        <Navbar />
        <HomePage />
        <Referanslarımız/>
        <BizKimiz />
        <Hizmetlerimiz />
        <HappyCustomer />
        <Comment />
        <Footer/>
    </div>
  )
}

export default ProjectMain