import React from 'react'
import './Comment.css'
import CommentCard from './CommentCard/CommentCard'
import man from '../../Assets/man.png'
import woman from '../../Assets/woman.png'
function Comment() {
  return (
    <div id='Referanslarımız' className='Comment'>
        <div className='CommentTitleDiv'>
            <h1>Hakkımızda Söylenenler</h1>
            <h3>Mutlu Otrera Ailesi !</h3>
        </div>

        <div className='CommentCardContainer'>
            <CommentCard commentImg={man} commentCardTitle={"Elinize Sağlık"} commentCardExplain={"Çok güzel çok değerli bir çalışma olmuş.Ne kadar doğru bir tercih yaptığımı her geçen gün daha iyi anlıyorum.Sağolun."} commentCardName={"Emrah K."} />
            <CommentCard commentImg={woman} commentCardTitle={"Herkese Tavsiye Ederim"} commentCardExplain={"Çok kısa sürede beklediğimden çok daha iyi sonuçlar elde ettim.İşletmem Otrera Dijital sayesinde gün geçtikçe büyüyor.Gönül rahatlığıyla herkese tavsiye ederim."} commentCardName={"Elif T."}/>
            <CommentCard commentImg={man} commentCardTitle={"Güvenilir Saygılı Bir İşletme"} commentCardExplain={"İlgi alaka işcilik mükemmel.Projemi kendi yorumlarıyla mükemmel hale getirdiler.Yaratıcılıkları ve çalışma tarzları çok profesyonel.Herkese tavsiye ederim.Güvenilir ve saygılı bir işletme."} commentCardName={"Onur A."} />
        </div>
        <div className='nocontextDiv'></div>
    </div>
  )
}

export default Comment