import React, { useEffect } from 'react';
import './HappyCustomer.css';

function HappyCustomer() {
  useEffect(() => {
    const counters = document.querySelectorAll(".counter");

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const counter = entry.target;
          counter.classList.add("counter-visible");
          startCounter(counter); // Start counting numbers
          observer.unobserve(counter); // Stop observing once done
        }
      });
    }, { threshold: 0.1 });

    counters.forEach(counter => observer.observe(counter));

    // Function to increment numbers
    function startCounter(counter) {
      const target = +counter.getAttribute("data-target");
      const speed = 200; // Speed of increment
      
      const updateCount = () => {
        const current = +counter.innerText;
        const increment = Math.ceil(target / speed);
      
        if (current < target) {
          counter.innerText = current + increment;
          setTimeout(updateCount, 20); // Update every 20ms
        } else {
          counter.innerText = target;
        }
      };

      updateCount();
    }

    // Clean-up function to disconnect observer
    return () => observer.disconnect();
  }, []);

  return (
    <div className='happy'>
      <div className='linediv'></div>
      <div className='HappyCustomer'>
        <div className='happyCustomerTitleDiv'>
          <h1>Mutlu Müşterilerimiz</h1>
          <h3>Büyük Otrera Digital Ailesi</h3>
        </div>

        <div className='happyCustomerList'>
          <ul>
            <li>
              <h1 data-target="2" className='counter'>0</h1>
              <h4>Ülke</h4>
            </li>

            <li>
              <h1 data-target="52" className='counter'>0</h1>
              <h4>Şehir</h4>
            </li>

            <li>
              <h1 data-target="230" className='counter'>0</h1>
              <h4>Müşteri</h4>
            </li>

            <li>
              <h1 data-target="3" className='counter'>0</h1>
              <h4>Yıl Profesyonel Tecrübe</h4>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default HappyCustomer;
