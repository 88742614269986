import React from 'react'
import './CommentCard.css'
function CommentCard({commentCardTitle, commentCardExplain,commentImg, commentCardName}) {
  return (
   <div className='commentCardMain'>
     <div className='CommentCard'>
        <div className='commentCardContenxDiv'>
        <h1>{commentCardTitle}</h1>
        <p>{commentCardExplain}</p>
        </div>
    </div>
    <img src={commentImg} />
    <h1>{commentCardName}</h1>

   </div>
  )
}

export default CommentCard