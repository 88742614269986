import React, { useState } from 'react';
import './Navbar.css';
import BizKimiz from '../BizKimiz/BizKimiz';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  
  const pageScroll = () => {

    window.location.href()

  }

  return (
    <div className="navbar">
      <div className="navbar-logo">
        <h1>OTRERA <h2>Digital</h2></h1>
      </div>

      <div className="navbar-menu">
        <ul className={isOpen ? "nav-links-mobile" : "nav-links"}>
       <a href='#BizKimiz'> <li>Biz Kimiz ?</li></a>
         <a href='#Hizmetlerimiz'> <li>Hizmetlerimiz</li></a>
         <a href='#Referanslarımız'> <li>Referanslarımız</li></a>

         <a href='tel:+908503463081'>
         <button className="navbar-button">+90 850 346 30 81</button>

         </a>
         
         
        </ul>
     
      </div>
    </div>
  );
}

export default Navbar;
